@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar
{
 @apply w-1.5 h-1
}

::-webkit-scrollbar-track
{
  @apply bg-slate-400/30 dark:bg-slate-600/30
}

::-webkit-scrollbar-thumb
{
  @apply bg-slate-400 dark:bg-slate-600 transition-all duration-150 ease-in-out hover:bg-orange-500 dark:hover:bg-orange-600 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.sidebar-icon{
    @apply relative flex items-center justify-center w-10 h-10 transition-all duration-150 ease-in-out cursor-pointer text-slate-700 bg-slate-50 rounded-3xl hover:text-white hover:bg-orange-500 hover:rounded-xl dark:hover:bg-orange-600 dark:hover:text-white dark:text-slate-300 dark:bg-slate-700;
}

.sidebar-tooltip{
    @apply absolute z-10 p-1 m-1 text-xs font-bold text-white transition-all duration-150 origin-left scale-0 rounded-md shadow-md whitespace-nowrap bg-slate-600 md:top-1 md:left-10 top-10;
}

.popUp{
    @apply absolute z-30 p-1 m-1 text-xs font-bold text-white transition-all duration-150 origin-left scale-0 rounded-md shadow-md whitespace-nowrap bg-slate-600 left-2 -top-5;
}
.sideicon-active{
    @apply p-0.5 rounded-xl
}

.sidenav-company-wrapper{
    @apply flex flex-row p-0.5 items-center justify-center md:flex-col bg-slate-400/80 dark:bg-slate-800  rounded-t-3xl rounded-b-3xl  hover:rounded-t-xl hover:rounded-b-xl transition-all duration-150 ease-in-out 
}

.main-bodyLayout{
    @apply px-1 pt-16 md:pl-[60px] md:pt-0 2xl:w-4/5 lg:w-full h-[100vh] overflow-y-auto 
}

.sidebarRight{
    @apply bg-slate-50 dark:bg-slate-700  w-full md:w-2/5 xl:w-1/4 2xl:w-1/5 rounded-lg my-0.5 p-0.5 shadow-lg shadow-slate-400  dark:shadow-slate-900 max-h-max h-[99.5vh]  fixed overflow-y-auto  transition-all duration-150 ease-in-out top-0 z-50
}

.sidebarRight2{
    @apply bg-slate-50 dark:bg-slate-800  w-full md:w-2/5 xl:w-1/4 2xl:w-1/5 rounded-md  p-1 shadow-lg shadow-slate-300  dark:shadow-slate-900 min-h-full h-full fixed overflow-y-auto  transition-all duration-150 ease-in-out top-0 z-[99]
}

.card{
    @apply  bg-slate-50 dark:bg-slate-900 rounded-xl p-4  transition-all duration-150 ease-in-out
}
.dynaCard{

    @apply p-3 transition-all duration-150 ease-in-out border shadow-lg rounded-xl bg-slate-50 dark:bg-slate-800 shadow-slate-300 dark:shadow-slate-900/50 hover:shadow-slate-400 dark:hover:shadow-slate-900 hover:border-orange-500 dark:hover:border-orange-600;
}
.input-field{
    @apply bg-transparent p-0.5 text-sm pl-2 border hover:border-orange-500 dark:hover:border-orange-600 border-slate-300 rounded-md focus:border-orange-500 dark:focus:border-orange-600 ring-0 focus:ring-0 focus:outline-none m-0.5 dark:border-slate-700 text-slate-600 dark:text-slate-300 placeholder:text-slate-500 dark:placeholder:text-slate-400
}

.input-field-fat{
    @apply bg-transparent p-1.5 text-sm pl-2 border hover:border-orange-500 dark:hover:border-orange-600 border-slate-300 rounded-md focus:border-orange-500 dark:focus:border-orange-600 ring-0 focus:ring-0 focus:outline-none m-0.5 dark:border-slate-700 text-slate-600 dark:text-slate-400 placeholder:text-slate-500
}
.search-field{
    @apply bg-slate-200 dark:bg-slate-900 p-1.5 text-sm pl-2 rounded-full border hover:border-orange-500 dark:hover:border-orange-600 border-slate-300  focus:border-orange-500 dark:focus:border-orange-600 ring-0 focus:ring-0 focus:outline-none m-0.5 dark:border-slate-700 text-slate-600 dark:text-slate-400 placeholder:text-slate-500
}

.input-field-prepend{
    @apply bg-slate-300 dark:bg-slate-700 rounded-l-md text-slate-500 p-0.5 m-0.5 text-sm  mr-0 border border-r-0 border-slate-300 dark:border-slate-700
}

.input-field-prepend-icon{
    @apply bg-slate-300 dark:bg-slate-700 rounded-l-md text-slate-500 p-1 m-0.5 text-sm  mr-0 border border-r-0 border-slate-300 dark:border-slate-700
}

.input-field-group{
    @apply bg-transparent read-only:font-semibold p-0.5 text-sm pl-1 border hover:border-orange-500  dark:hover:border-orange-600 border-slate-300 rounded-r-md focus:border-orange-500 dark:focus:border-orange-600 ring-0 focus:ring-0 focus:outline-none  m-0.5 ml-0 dark:border-slate-700 text-slate-600 dark:text-slate-400 placeholder:text-slate-500 
}

.checkbox{
    @apply appearance-none h-4 w-4 border border-slate-300 rounded-md bg-slate-300 dark:bg-slate-600 dark:border-slate-700 checked:bg-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500 checked:border-orange-500 focus:outline-none transition duration-150  align-top bg-no-repeat bg-center bg-contain cursor-pointer 
}

.checkbox-checklist{
    @apply appearance-none h-4 w-4 border border-slate-300 rounded-md bg-slate-300 dark:bg-slate-600 dark:border-slate-700 checked:bg-green-400 dark:checked:bg-green-400 dark:checked:border-slate-700 checked:border-slate-50  checked:border-2 ring-1 ring-transparent  checked:ring-green-500 focus:outline-none transition duration-150  align-top bg-no-repeat bg-center bg-contain cursor-pointer 
}

.checkbox-optionslist{
    @apply appearance-none h-3 w-3 border border-slate-300 rounded-full bg-slate-300 dark:bg-slate-600 dark:border-slate-700 checked:bg-green-400 dark:checked:bg-green-400 dark:checked:border-slate-700 checked:border-slate-50  checked:border-2 ring-1 ring-transparent  checked:ring-green-500 focus:outline-none transition duration-150  align-top bg-no-repeat bg-center bg-contain cursor-pointer 
}

.checkbox-label{
    @apply text-xs text-slate-400  
}

.input-field-select{
    @apply  bg-transparent text-slate-400 text-sm border border-slate-300 rounded-md hover:border-orange-500 dark:hover:border-orange-600 ring-0 focus:ring-0 focus:outline-none  m-0.5 dark:border-slate-700 dark:text-slate-500 placeholder:dark:text-slate-400 
}

.input-field-select-append{
    @apply bg-transparent text-slate-400 text-sm border border-l-0 border-slate-300 rounded-r-md hover:border-orange-500 dark:hover:border-orange-600 ring-0 focus:ring-0 focus:outline-none  dark:border-slate-700 dark:text-slate-500 placeholder:dark:text-slate-400 
}
/* react selectt multi value container value text color */
.css-1h89b5x{
    @apply font-semibold text-slate-500 dark:text-slate-400
}
.input-field-select-fat{
    @apply bg-transparent text-slate-400 text-sm border border-slate-300 rounded-md hover:border-orange-500 dark:hover:border-orange-600 ring-0 focus:ring-0 focus:outline-none  m-0.5 dark:border-slate-700 dark:text-slate-500 placeholder:dark:text-slate-400 
}

.submit-btn{
    @apply  p-1 m-0.5 font-semibold transition-all duration-150 ease-in-out border rounded-md 
    border-slate-300 text-slate-400 bg-slate-200  hover:bg-orange-500 
    hover:text-slate-50 hover:border-orange-500 dark:border-slate-600 dark:bg-slate-700 
    dark:text-slate-500 dark:hover:text-slate-50 dark:hover:bg-orange-600 dark:hover:border-orange-600 
    disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-slate-400 disabled:hover:bg-slate-200 disabled:hover:border-slate-300 
    dark:disabled:hover:text-slate-500 dark:disabled:hover:bg-slate-700 dark:disabled:hover:border-slate-500 
}

.login-btn{
    @apply p-2 m-1 font-semibold transition-all duration-150 ease-in-out border rounded-md 
    border-slate-400 text-slate-600 dark:text-slate-300 bg-slate-50 dark:bg-slate-800 dark:border-slate-700 
     hover:border-orange-500 hover:text-orange-500 dark:hover:border-orange-600 dark:hover:text-orange-600 dark:hover:bg-slate-900
}

.signUp-btn{
    @apply p-2 m-1 font-semibold transition-all duration-150 ease-in-out rounded-md 
    text-white dark:text-white bg-orange-500 dark:bg-orange-600 border border-orange-500 dark:border-orange-600
    hover:bg-orange-400 dark:hover:bg-orange-500
}
.or-option{
    @apply text-lg text-slate-600 dark:text-slate-400 text-center relative
}

.or-option::before{
    @apply content-['\a0'] h-[1px] bg-slate-400 dark:bg-slate-600  absolute top-[58%] overflow-hidden w-[38%] left-5
}
.or-option::after{
    @apply content-['\a0'] h-[1px] bg-slate-400 dark:bg-slate-600 absolute top-[58%] overflow-hidden w-[38%] right-5
}

.jobPost-btn{
    @apply w-full text-center  font-semibold py-0.5 px-3  bg-orange-500 text-slate-50 rounded-md hover:bg-orange-400 transition-all duration-150 ease-in-out
}
.plus-add-task{
    @apply w-10 h-10 rounded-full text-slate-600 dark:text-slate-300 border border-slate-300 dark:border-slate-700 
    hover:bg-orange-500 dark:hover:bg-orange-600 hover:text-slate-50 dark:hover:text-slate-50 hover:border-orange-600 dark:hover:border-orange-500
    transition-all ease-in-out duration-150 
    inline-flex items-center justify-center
}
.primary-btn{
    @apply w-auto text-center font-bold p-0.5 px-1 bg-orange-500 text-slate-50 rounded-md hover:bg-orange-400 transition-all duration-150 ease-in-out
}
.option-btn{
    @apply w-auto text-center font-bold p-0.5 px-1 text-slate-50 rounded-md transition-all duration-150 ease-in-out
}
.link{
    @apply text-xs text-orange-500 underline text-center
}

.createJobCard{
    @apply p-1 mr-1 w-auto bg-slate-50  dark:bg-slate-900 rounded-md 
}


.childJob{
    @apply relative p-0.5 mt-1 items-center rounded-md border  bg-slate-100 dark:bg-slate-800  
}
.editChildJob{
    @apply relative p-0.5 mt-1  rounded-md  bg-slate-50  dark:bg-slate-800 shadow-lg shadow-slate-400 dark:shadow-[#0B0F1C]  
}
.forwardJob{
    @apply relative p-0.5 mt-1  rounded-md  bg-orange-50 dark:bg-orange-900/40 shadow-lg shadow-slate-400 dark:shadow-[#0B0F1C]  
}
.forwardJobArrow{
    @apply absolute right-[2rem] z-0 -top-4 content-[''] w-0 h-0  border-8 border-r-transparent border-l-transparent border-b-orange-50  dark:border-b-orange-900/40  border-t-transparent
}
.editChildArrow{
    @apply absolute right-[3.65rem] z-0 -top-4 content-[''] w-0 h-0  border-8 border-r-transparent border-l-transparent border-b-slate-50  dark:border-b-slate-800  border-t-transparent
}

.assigneeMenuArrow{
    @apply absolute left-1.5 z-0 -top-4 content-[''] w-0 h-0  border-8 border-r-transparent border-l-transparent border-b-slate-100  dark:border-b-slate-700  border-t-transparent
}

.jobDescFloat{
    @apply absolute left-0 z-50 p-1 px-2 transition-all duration-150 ease-in-out origin-bottom-left scale-0 rounded-md shadow-lg group-hover:scale-100 -top-9 bg-slate-50 dark:bg-slate-700 dark:shadow-slate-900 shadow-slate-400 hover:block 
}
.jobDescFloatArrow{
    @apply absolute left-1.5 z-0 -bottom-4 content-[''] w-0 h-0  border-8 border-r-transparent border-l-transparent border-t-slate-50  dark:border-t-slate-700  border-b-transparent
}

.icon-btn{
    @apply  p-0.5 w-min border 
    border-slate-300 text-slate-400 rounded-md  bg-slate-100 dark:bg-slate-700 dark:border-slate-700 
    dark:hover:bg-orange-600 dark:hover:border-orange-600 hover:bg-orange-500 hover:border-orange-500 
    hover:text-slate-50 transition-all duration-150 ease-in-out active:bg-orange-500
    disabled:opacity-50 disabled:hover:bg-slate-100 disabled:hover:text-slate-400 dark:disabled:hover:bg-slate-700 dark:disabled:hover:text-slate-400

}

.delete-btn{
    @apply  p-0.5 w-min text-white rounded-md  bg-red-600 dark:bg-red-700 
    dark:hover:bg-red-500 hover:bg-red-500 
    transition-all duration-150 ease-in-out active:bg-red-500
    disabled:opacity-50 disabled:hover:bg-slate-100 disabled:hover:text-slate-400 dark:disabled:hover:bg-slate-700 dark:disabled:hover:text-slate-400

}
.icon-btn.active{
    @apply bg-orange-500 text-slate-50 border-orange-500 transform translate-x-1
}

.done-btn{
    @apply relative inline-flex rounded-full h-5 w-5 bg-green-500 text-white dark:text-slate-900 ring-0 hover:ring-2 hover:ring-offset-2 hover:ring-offset-slate-200 dark:hover:ring-offset-slate-900 ring-green-500 transition-all duration-150 ease-in-out
}

.revert-btn{
    @apply relative inline-flex rounded-full h-5 w-5 border border-slate-400 dark:border-slate-600 bg-transparent hover:bg-slate-50 dark:hover:bg-slate-800 text-orange-500 dark:text-orange-600 ring-offset-slate-50 dark:ring-offset-slate-800 ring-0 hover:ring-2 hover:ring-offset-2 ring-orange-500 transition-all duration-150 ease-in-out
}

.save-btn{
    @apply  p-0.5 w-min border border-green-400 text-green-500 rounded-md bg-green-200 font-semibold 
    dark:bg-green-800/50 dark:text-green-400 dark:border-green-600 dark:hover:bg-green-600 dark:hover:border-green-600 
    hover:bg-green-500 hover:border-green-500 hover:text-slate-50 dark:hover:text-slate-50
    transition-all duration-150 ease-in-out active:bg-green-500 
    disabled:border-slate-300 disabled:text-slate-400 disabled:dark:text-slate-400 disabled:bg-slate-100 disabled:dark:bg-slate-700 disabled:dark:border-slate-700
    disabled:opacity-50 disabled:hover:bg-slate-100 disabled:hover:text-slate-400 dark:disabled:hover:bg-slate-700 dark:disabled:hover:text-slate-400

}

.display-btn{
    @apply  w-min text-orange-400 dark:text-orange-600
}

.addchild{
    @apply text-slate-600 dark:text-slate-400 rounded-full hover:bg-orange-500 hover:text-slate-50 dark:hover:bg-orange-600 dark:hover:text-slate-50 m-1 transition-all duration-150 ease-in-out
}
.addChild-wrapper{
    @apply bg-slate-50 dark:bg-slate-800 rounded-b-full shadow-lg shadow-slate-400 dark:shadow-[#0B0F1C]
}

.toolbarNote{
    @apply bg-slate-300 dark:bg-slate-700 p-1 rounded-t-md
}

.rdw-option-wrapper{
    @apply bg-slate-200 dark:bg-slate-600 p-1 hover:bg-orange-500 dark:hover:bg-orange-600 transition-all duration-150 ease-in-out text-slate-600 dark:text-slate-300 rounded-sm
}

.rdw-dropdown-optionwrapper{
    @apply bg-slate-300 dark:bg-slate-700 p-1 
}

.rdw-dropdownoption-highlighted{
    @apply bg-slate-200 dark:bg-slate-500 
}

.rdw-dropdown-selectedtext{
   @apply text-slate-600 dark:text-slate-400  bg-slate-200 dark:bg-slate-600
}

.rdw-dropdownoption-default{
    @apply text-slate-600 dark:text-slate-400 
}

.rdw-dropdownoption-active{
    @apply bg-slate-200 dark:bg-slate-500
}

.editorNote{
    @apply bg-slate-50 p-2 text-slate-700 dark:bg-slate-900  dark:text-slate-300 rounded-b-md
}
.wrapperNote{
    @apply  rounded-md mb-1
}

.rdw-option-wrapper img{
    @apply dark:invert dark:opacity-50
}

.rdw-editor-main{
 @apply min-h-full h-full max-h-96 !important
}

.DraftEditor-root{
 @apply min-h-[6rem]  max-h-full !important;
}

.popUpBox{
    @apply origin-right absolute right-0 p-1 bg-slate-50 ml-3 top-0 mr-1 border border-slate-300 dark:border-slate-700 rounded-md z-50 dark:bg-slate-900 text-slate-700 dark:text-slate-400 transition-all duration-150 ease-in-out
}
.repeater-btn{
    @apply text-sm font-semibold hover:bg-orange-500 hover:text-slate-50 dark:hover:bg-orange-600 dark:hover:text-slate-50 border border-slate-300 dark:border-slate-700 p-1 w-full rounded-md  whitespace-nowrap transition-all duration-150 ease-in-out
}
.accept-btn{
    @apply text-sm text-green-600 dark:text-green-500 bg-green-200 dark:bg-green-700/30 border border-green-500 dark:border-green-500 p-1  rounded-md whitespace-nowrap hover:bg-green-500 dark:hover:bg-green-500 dark:hover:text-white  hover:text-white transition-all duration-150 ease-in-out
}

.addTask-btn{
    @apply text-sm font-semibold text-white bg-green-600 dark:bg-green-700  p-1  rounded-md whitespace-nowrap hover:bg-green-500 dark:hover:bg-green-500 transition-all duration-150 ease-in-out
}

.forwardTask-btn{
    @apply text-sm font-semibold text-white bg-green-600 dark:bg-green-700 border px-1 py-0.5 rounded-md whitespace-nowrap hover:bg-green-500 dark:hover:bg-green-500 transition-all duration-150 ease-in-out
}

.info-btn{
    @apply text-sm text-slate-700 dark:text-slate-400 border border-slate-300 dark:border-slate-700 p-1  rounded-md whitespace-nowrap hover:bg-orange-200 dark:hover:bg-orange-600/30 dark:hover:text-orange-500  hover:text-orange-700 transition-all duration-150 ease-in-out
}
.reject-btn{
    @apply text-sm text-slate-700 disabled:hover:bg-transparent disabled:hover:text-slate-400 dark:disabled:hover:bg-transparent dark:disabled:hover:text-slate-600 dark:text-slate-400 border border-slate-300 dark:border-slate-700 p-1  rounded-md whitespace-nowrap hover:bg-red-200 dark:hover:bg-red-600/30 dark:hover:text-red-500  hover:text-red-700 transition-all duration-150 ease-in-out
}
.react-datepicker-wrapper{
    @apply w-auto !important
}
.react-datepicker{
    @apply bg-slate-100 border-slate-300 dark:bg-slate-800 dark:border-slate-700 shadow-xl !important;
}
.react-datepicker__header {
    @apply bg-slate-200 border-b-slate-300 shadow-xl dark:bg-slate-700 dark:border-b-slate-700 !important;
}
.react-datepicker__day--disabled{
 @apply opacity-40 dark:opacity-20 !important;
}


.react-datepicker-popper{
    @apply z-50 !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    @apply border-b-slate-200 dark:border-b-slate-700 !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    @apply border-b-slate-200 dark:border-b-slate-700 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    @apply text-slate-600 dark:text-slate-300 border border-transparent hover:bg-transparent !important
}
.react-datepicker__day{
    @apply hover:border-orange-500  !important
}
.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
    @apply bg-orange-500 text-slate-50 hover:bg-slate-200 hover:text-slate-600 dark:hover:bg-slate-700  dark:hover:text-slate-200 !important
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    @apply text-slate-600 dark:text-slate-400 !important
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    @apply border-slate-600 dark:border-slate-400  !important
}
.react-datepicker__close-icon::after{
    @apply bg-orange-500 text-[14px] w-[20px] h-[20px] !important;
}

.react-datepicker__month-dropdown-container--scroll{
    @apply text-slate-600 dark:text-slate-400 border border-slate-300 dark:border-slate-500 p-1 w-20 mx-0.5 rounded-md !important
}
.react-datepicker__month-dropdown{
    @apply bg-slate-300 dark:bg-slate-800 border-0 shadow-lg shadow-slate-400 dark:shadow-slate-900  !important
}
.react-datepicker__month-option{
    @apply bg-transparent hover:bg-orange-500 hover:text-slate-50 py-1 !important
}

.react-datepicker__year-dropdown{
    @apply bg-slate-300 dark:bg-slate-800 border-0 shadow-lg shadow-slate-400 dark:shadow-slate-900  !important
}
.react-datepicker__year-option{
    @apply bg-transparent hover:bg-orange-500 hover:text-slate-50 py-1 !important
}

.react-datepicker__year-dropdown-container--scroll{
    @apply text-slate-600 dark:text-slate-400 border border-slate-300 dark:border-slate-500 p-1 w-20 mx-0.5 rounded-md !important
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    @apply hidden !important
}



/* post jobs */

.postJobCard{
    @apply p-1 my-2 mr-1 ml-0 rounded-md w-auto bg-slate-50 dark:bg-slate-900 
}

.joblistChild{
    @apply  transition-all duration-150 ease-in-out p-1 
}

.check-item{
    @apply p-1 text-sm bg-slate-300/50 dark:bg-slate-700 border border-transparent rounded-md transition-all duration-150 ease-in-out m-0.5 hover:border-slate-300 dark:hover:border-slate-600 flex items-center justify-between
}

.drag-handler{
   @apply p-0.5 text-sm mr-1 rounded-md font-semibold text-slate-400 bg-transparent hover:text-slate-600 hover:bg-slate-300
}

.magic-input{
    @apply bg-transparent text-slate-600 dark:text-slate-400 focus:outline-none text-sm font-semibold  w-11/12  placeholder:text-slate-400 dark:placeholder:text-slate-600
}

.add-check-btn{
    @apply p-1 text-sm bg-slate-50 dark:bg-slate-700 border border-slate-300 rounded-md transition-all duration-150 ease-in-out m-0.5 hover:border-slate-500 text-slate-400  hover:text-slate-600 dark:hover:text-slate-400 dark:text-slate-500 dark:border-slate-600 dark:hover:border-slate-500 whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed
}
.add-user-btn{
    @apply p-1 text-sm bg-slate-300 dark:bg-slate-700 border border-transparent rounded-full transition-all duration-150 ease-in-out m-0.5 hover:border-slate-300 text-slate-400  hover:text-slate-600 dark:hover:text-slate-400 dark:text-slate-500 whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed
}


.activity-box{
    @apply bg-slate-200 rounded-md dark:bg-slate-900 p-3 h-36 overflow-y-auto 
}

.employee-log-wrapper{

    @apply bg-slate-200 rounded-md dark:bg-slate-900 p-3 overflow-y-auto 
}
.sidebar-job-wrapper{
    @apply h-[54vh] overflow-y-auto
}
.tabList{
    @apply flex flex-row md:h-[99vh] md:flex-col bg-slate-300 dark:bg-slate-700 md:rounded-t-none md:rounded-l-md rounded-t-md
}
.tabOptions{
    @apply  hover:bg-slate-200 dark:hover:bg-slate-600 inline-flex flex-col md:flex-row gap-2 justify-start items-center  md:text-sm p-2 w-16 md:w-full  transition-all duration-150 ease-in-out text-slate-600 dark:text-slate-400
}
.tab-active{
    @apply text-orange-500 border-transparent bg-slate-50 dark:bg-slate-900 first:rounded-tl-md dark:text-orange-600 hover:bg-slate-50 dark:hover:bg-slate-800 
}
.tabPanel{
    @apply lg:mr-2 h-[99vh] bg-slate-50 dark:bg-slate-900 md:rounded-tr-md rounded-b-md md:rounded-br-md rounded-tr-none p-2 overflow-auto 
}
.default-btn{
    @apply p-2 px-3 m-1 bg-slate-300 dark:bg-slate-800 text-slate-600 dark:text-slate-400 font-semibold whitespace-nowrap inline-flex items-center justify-center focus:outline-none focus:ring-0 hover:bg-orange-500 disabled:cursor-not-allowed disabled:hover:bg-slate-300 dark:disabled:hover:bg-slate-900  dark:hover:bg-orange-600 dark:hover:text-slate-50 disabled:hover:text-slate-600 dark:disabled:hover:text-slate-400 rounded-md transition-all duration-150 ease-in-out hover:text-slate-50 disabled:opacity-50  
}
.option-grid{
    @apply   bg-slate-100 dark:bg-slate-800 rounded-md pb-1 border border-slate-300 dark:border-slate-700
}
.optionLine{
    @apply flex items-center justify-start gap-2 m-1 hover:bg-slate-200 dark:hover:bg-slate-700 rounded-md transition-all duration-150 ease-in-out
}
.tick-btn{
    @apply p-0.5 transition-all duration-150 ease-in-out rounded-full border hover:border-orange-500 hover:text-orange-500 hover:bg-orange-200 dark:hover:bg-orange-900 scale-110 hover:scale-125 mt-1
}
.validBox{
    @apply absolute top-12 left-0 p-1 bg-slate-50/50 backdrop-blur-sm dark:bg-slate-900/50 dark:backdrop-blur-sm rounded-md shadow-md shadow-slate-500 dark:shadow-slate-900 text-xs text-slate-400  font-semibold z-30
}
.showPass{
    @apply absolute right-0 mr-[0.05rem] top-[0.3rem] z-0  p-1 rounded-md hover:text-slate-50 hover:bg-orange-500 dark:hover:text-slate-50 dark:hover:bg-orange-500 transition-all ease-in-out duration-150 cursor-pointer
}
.escapeToaster{
    @apply bg-slate-50 dark:bg-slate-700 text-slate-600 dark:text-slate-400
}

.categoryAccessCard{
   @apply px-2 py-2 my-1 transition-all duration-150 ease-in-out border border-slate-300 dark:border-slate-800 rounded-md  bg-slate-200 hover:bg-slate-50 dark:bg-slate-800 dark:hover:bg-slate-700
}
.ul-wrapper{
    @apply  rounded-md  bg-slate-50 border border-slate-300 dark:bg-slate-800 dark:border-slate-700  
}

.li-wrapper{
    @apply px-2 py-1 border-b border-slate-300 dark:border-slate-700 text-slate-600 dark:text-slate-400 
    transition-all duration-150 ease-in-out cursor-pointer
    hover:bg-orange-500 dark:hover:bg-orange-600 hover:text-slate-50 dark:hover:text-slate-50  whitespace-nowrap
}

/* pagination */
.pagination-container{
    @apply flex items-center justify-end  p-2 gap-1 rounded-md mr-1 mt-8
}

.prevLink,.nextLink, .pageLink{
    @apply py-1.5 px-3 text-sm rounded-md border border-transparent bg-slate-50 dark:bg-slate-700 dark:text-slate-400 text-slate-600 font-semibold hover:border-orange-500 dark:hover:border-orange-600 transition-all duration-150 ease-in-out
}
.activePageLink{
    @apply bg-orange-500 dark:bg-orange-600 text-slate-50 dark:text-slate-50
}
.disablePageLink{
    @apply opacity-50
}

/* pagination */


.companyBtn{
    @apply flex items-center justify-start gap-2 p-1 m-1 font-semibold rounded-md md:w-full whitespace-nowrap 
            bg-slate-50 dark:bg-slate-900 text-slate-600 dark:text-slate-300 
            hover:bg-orange-200 dark:hover:bg-orange-800 transition-all ease-in-out duration-150
}
.activeCompanyBtn{
    @apply bg-orange-500 text-slate-50 dark:bg-orange-600 !important
}

.companyBtn-add{
    @apply flex items-center justify-center gap-2 p-1 m-1 font-semibold rounded-md md:w-full whitespace-nowrap  
            bg-slate-50 dark:bg-slate-900 text-slate-600 dark:text-slate-300 
            hover:bg-orange-200 dark:hover:bg-orange-800  transition-all ease-in-out duration-150
}
.notificationCircle-wrapper{
    @apply absolute right-0 top-1 flex h-4 w-4 
}
.animation-wrapper{
    @apply animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75 z-0
}
.dropdown-wrapper{
    @apply absolute rounded-b-md shadow-lg  left-0 bg-slate-50 dark:bg-slate-800 shadow-slate-400 dark:shadow-slate-900 z-[99] overflow-y-auto max-h-56
}

.value-wrapper{
    @apply  rounded-full h-4 w-4 bg-red-500 text-white font-semibold text-xs text-center items-center z-10
}

.selectOptions{
    @apply cursor-pointer py-1 px-2 whitespace-nowrap text-xs font-semibold transition-all duration-150 ease-in-out flex items-center justify-start gap-1
}
.selectOptions:hover{
    @apply bg-slate-300 dark:bg-slate-600 !important
}
.twitter-picker{
    @apply bg-slate-50 dark:bg-slate-700 !important
}
.prompt-wrapper{
    @apply absolute -top-6 left-10 z-50 bg-slate-50 rounded-md  dark:bg-slate-600 p-0.5  shadow-lg shadow-slate-400 dark:shadow-slate-900
}

.taskSearch-btn{
    @apply rounded-full bg-slate-300 hover:bg-slate-400 dark:hover:bg-slate-800 text-slate-500 dark:bg-slate-900 dark:text-slate-400 p-1 transition-all duration-150 ease-in-out
}
.taskSearch-btn.active{
    @apply bg-orange-500 dark:bg-orange-600 text-white  ring-2 ring-orange-200 dark:ring-orange-900 ring-offset-2 ring-offset-transparent !important
}

.notificationBar{
    @apply relative cursor-pointer rounded-md border p-2 m-1 hover:bg-white hover:border-orange-500 dark:hover:bg-slate-900 dark:hover:border-orange-600 transition-all duration-150 ease-in-out
}


.sender-wrapper{
    @apply flex items-start justify-start gap-1 flex-row-reverse
}

.reciever-wrapper{
    @apply flex items-start justify-start gap-1 
}


.sender{
    @apply text-right flex items-center justify-end gap-1 flex-row-reverse flex-wrap  mr-1 
}
.reciever{
    @apply flex items-center justify-start gap-1 flex-wrap 
}

.msg-profile-wrapper{
    @apply  relative 
}
.msg-profile-img{
    @apply max-w-min max-h-min  w-7 h-7 rounded-full
}
.msg-profile-type-img{
    @apply max-w-min max-h-min  w-6 h-6 rounded-full
}
.msg-profile-name{
    @apply  whitespace-nowrap origin-bottom  text-slate-500  text-sm
}

.reciever > .msgText{
    @apply  rounded-l-md rounded-r-3xl transition-all duration-150 ease-in-out  
}

.sender > .msgText{
    @apply bg-orange-500 dark:bg-orange-700  text-white  rounded-l-3xl rounded-r-md  
}

.msgText{
  @apply text-sm  px-2 py-1 my-0.5 
}

.msg-input-wrapper{
    @apply grid grid-cols-10  items-center gap-1 text-slate-600  dark:text-slate-400 bg-slate-300 dark:bg-slate-700 p-2 w-full
}
.msg-input{
    @apply w-full bg-transparent text-sm ring-0 outline-none !important
}
aside.emoji-picker-react{
    @apply bg-slate-50 dark:bg-slate-900 shadow-none border border-slate-300 dark:border-slate-700 !important 
}
.emoji-picker-react .emoji-categories button{
    @apply bg-slate-400 dark:bg-slate-600 rounded-full h-5 my-4 p-1 !important
}
.emoji-search{
    @apply bg-transparent rounded-3xl border border-slate-300 dark:border-slate-700 !important
}
.emoji-picker-react .emoji-group:before{
    @apply  dark:text-slate-500 text-slate-400 bg-slate-50 dark:bg-slate-900 !important
}
.action-btn{
    @apply text-white w-7 disabled:opacity-30 h-7 ring-2 ring-offset-2 ring-transparent hover:ring-orange-500 dark:hover:ring-orange-600 ring-offset-slate-50 dark:ring-offset-slate-700 relative rounded-full p-1 font-semibold text-sm whitespace-nowrap flex items-center justify-center gap-1
}
.action-btn-text{
    @apply scale-0 z-[50] absolute py-0.5 px-1 left-0 transition-all duration-150 ease-in-out origin-bottom -top-4 rounded-md bg-slate-700 text-white text-xs font-semibold
}
.msgToggleBtn{
    @apply bg-orange-500 dark:bg-orange-600 text-white p-2 rounded-br-none rounded-full hover:bg-orange-400 dark:hover:bg-orange-500 transition-all duration-150 ease-in-out
}

.stopwatch{
    @apply bg-red-200 px-4 py-2 rounded-3xl dark:bg-red-800/40 
}

.numbers-Stopwatch{
    @apply text-3xl font-semibold text-red-600 dark:text-red-400
}

.announcement-bg{
    
    @apply p-2 text-sm font-bold text-white rounded-xl m-0.5 animate-[bgFlow_5s_ease-in-out_infinite] bg-gradient-to-r from-red-500 to-red-900 bg-[length:600px_600px]
    
}
.senderImg-wrapper{
    @apply text-right flex items-start justify-start gap-1 flex-row-reverse   my-0.5 
}
.recieverImg-wrapper{
    @apply flex items-start justify-start gap-1 my-0.5 
}

.msgOp-btn{
    @apply text-slate-500 text-xs font-semibold p-1 rounded-full
}

.sender-Img-bg{
    @apply bg-orange-500 dark:bg-orange-600 p-1 rounded-lg text-sm text-white 
}
.reciever-Img-bg{
    @apply bg-slate-300 p-1 text-sm rounded-lg dark:bg-slate-700 text-slate-600 dark:text-slate-300
}